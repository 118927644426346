import axios from "axios";
import { baseURL } from "../utils/URL";

const onSaveScan = async (postData: any) => {
  try {
    const response = await axios.post(`${baseURL}save/add`, postData);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetSaveScan = async (postData: any) => {
  try {
    const response = await axios.post(`${baseURL}save/getuserscans`, postData);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onDeleteSaveScan = async (id: any) => {
  try {
    const response = await axios.post(`${baseURL}save/delete/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onVerifyUser = async (id: any) => {
  try {
    const response = await axios.post(`${baseURL}user/verify/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onCreateCheckout = async (postdata: any) => {
  try {
    const response = await axios.post(`${baseURL}premium/checkout`, postdata);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onCreateSubscription = async (postdata: any) => {
  try {
    const response = await axios.post(`${baseURL}premium/subscribe`, postdata);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const getuserById = async (id: any) => {
  try {
    const response = await axios.get(`${baseURL}user/getuser/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const getallusers = async (from: string, to: string, page: number) => {
  try {
    const response = await axios.get(
      `${baseURL}user/getall?page=${page}&limit=20&from=${from}&to=${to}`
    );
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const Cancelsubscription = async (id: any) => {
  try {
    const response = await axios.post(`${baseURL}premium/cancel/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const getSubscription = async (id: any) => {
  try {
    const response = await axios.get(`${baseURL}premium/retrive/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onCreateUser = async (postData: any) => {
  try {
    const response = await axios.post(`${baseURL}auth/signup`, postData);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateUser = async (postData: any, id: string) => {
  try {
    const response = await axios.patch(`${baseURL}user/update/${id}`, postData);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

export {
  onSaveScan,
  onCreateCheckout,
  onDeleteSaveScan,
  getuserById,
  onVerifyUser,
  onUpdateUser,
  onCreateUser,
  Cancelsubscription,
  getallusers,
  onCreateSubscription,
  getSubscription,
  onGetSaveScan,
};
