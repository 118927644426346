import { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { useStateSetter } from "../../../../hooks/statehooks/UseStateSettersHook";
import SelectCountry from "../SubFlows/Market/SelectCountry";
import SelectMarket from "../SubFlows/Market/SelectMarket";
import SelectState from "../SubFlows/Market/SelectState";
import { indicators } from "../../../../constants/data/data";
import { NavigateBtns } from "../../../../components/generic/NavigateBtns";
import { CiStar } from "react-icons/ci";
import { RxStarFilled } from "react-icons/rx";
import { tab } from "@testing-library/user-event/dist/tab";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../components/generic/accordion";

export default ({ nextStep, previousStep, setpostdata }: any) => {
  const { setNextAction, setPrevAction } = useStateSetter();
  const [mainActive, setmainActive] = useState<number>(1);
  const [favorites, setFavourites] = useState<any[]>([]);
  const [selectedindi, setselectedindi] = useState<any[]>([]);
  const [activetab, setactivetab] = useState("");
  useEffect(() => {
    setNextAction(nextStep);
    setPrevAction(previousStep);
  }, []);
  const tabs = ["Favourite", "All"];

  useEffect(() => {
    setactivetab(tabs[1]);
  }, []);

  useEffect(() => {
    favorites.length > 0 &&
      localStorage.setItem("favindi", JSON.stringify(favorites));
    console.log("Setitem", favorites);
  }, [favorites]);

  useEffect(() => {
    const retrievedArray = JSON.parse(localStorage.getItem("favindi") ?? "[]");
    setFavourites(retrievedArray);
    console.log("getitem", localStorage.getItem("favindi"));
  }, []);

  const TabItem = ({ item }: any) => (
    <div
      onClick={() => {
        setactivetab(item);
      }}
      className={`${
        activetab == item ? " bg-blue-600" : "bg-blue-300"
      } px-4 py-1 text-xs rounded  cursor-pointer text-white`}
    >
      {item}
    </div>
  );
  const Indicatoritem = ({ item }: any) => (
    <div
      className={`bg-blue-100 max-w-[80vw] overflow-x-scroll inline-block cursor-pointer m-1 rounded px-2 py-1 ${
        selectedindi?.includes(item) ? "bg-primary text-white" : ""
      } text-sm text-gray-700 truncate`}
    >
      {!favorites.some((gg: any) => gg.code == item.code) ? (
        <CiStar
          onClick={() => {
            setFavourites((prev: any) => prev.concat([item]));
          }}
          size={20}
          className="inline "
        />
      ) : (
        <RxStarFilled
          onClick={() => {
            setFavourites((prev: any) =>
              prev.filter((kl: any) => kl.code != item.code)
            );
          }}
          size={20}
          className="inline text-orange-300"
        />
      )}

      <span
        onClick={() => {
          setselectedindi((prev: any) => {
            if (selectedindi?.includes(item as never)) {
              return prev.filter((kl: any) => kl != item);
            } else {
              return prev.concat([item]);
            }
          });
        }}
      >
        {item.name}
      </span>
    </div>
  );

  return (
    <div className="relative self-center        place-self-center w-full   md:!w-[600px] p-2 md:mt-6">
      <div className="flex flex-col md:flex-row gap-4 md:items-center py-2 border-2 rounded  px-2">
        <div>
          <p className="text-gray-500 font-semibold text-xs md:text-sm  md:text-center ">
            Select 1- 3 indicator items
          </p>
          <div className="flex gap-2">
            {tabs.map((kj: any) => (
              <TabItem item={kj} />
            ))}{" "}
          </div>
        </div>
        <p className="text-gray-500 font-semibold  text-xs md:text-sm  md:text-center ">
          Selected ({selectedindi.length})
        </p>
      </div>
      <div className="!md:w-full flex  h-[400px] md:h-[270px] p-3 justify-between  border-x-2 border-b-2  w-[90vw] md:w-full flex-col pb-20 md:pb-1 rounded overflow-y-scroll ">
        {activetab == "All" ? (
          indicators.map((ik: any) => (
            <div className="flex   py-4 flex-col w-full">
              <Accordion type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger> {ik.categoryname}</AccordionTrigger>
                  <AccordionContent>
                    <div className="  w-[80%] gap-1">
                      {ik.items.map((il: any) => (
                        <Indicatoritem item={il} />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          ))
        ) : (
          <div className="gap-1">
            {favorites.map((il: any) => (
              <Indicatoritem item={il} />
            ))}
          </div>
        )}
<div className="absolute md:relative bottom-5">

        <NavigateBtns
          shownext
           
          actionPrev={() => {
            previousStep();
          }}
          nextCondition={selectedindi.length <= 3 && selectedindi.length > 0}
          actionNext={() => {
            setpostdata((prev: any) => {
              return { ...prev, indicators: selectedindi };
            });
            nextStep();
          }}
        />
        </div>
      </div>
    </div>
  );
};
