import React, { ReactEventHandler } from "react";
import { Button } from "../forms";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext, MdScanner } from "react-icons/md";
import { IoMdQrScanner } from "react-icons/io";

interface NavigateBTNsProps {
  actionNext: ReactEventHandler;
  actionPrev: ReactEventHandler;
  nextCondition: boolean;
  shownext?:boolean;
  islast?:boolean;
  showprev?:boolean;
}
const NavigateBtns: React.FC<NavigateBTNsProps> = ({
  actionNext,
  islast,
  actionPrev,
  nextCondition,
  shownext, showprev
}) => {
  return (
  <div className="w-full justify-items-center">

    <div className=" w-[80vw] md:w-[50%]  m-2 self-end flex justify-end items-center gap-3 md:gap-6">
     { showprev &&<Button
        outlined
        text={
          <>
          
            <MdOutlineNavigateBefore className="inline" /> Previous
          </>
        }
        onBtnClick={actionPrev}
      />
      }

   {shownext &&   <Button
        disabled={!nextCondition}
        text=  {!islast?<>  <MdOutlineNavigateNext className="inline" /> Next </> :<>  <IoMdQrScanner className="inline" /> Scan  </>}
           
         
        onBtnClick={actionNext}
      />}
    </div>
        
  </div>
  );
};

export { NavigateBtns };
