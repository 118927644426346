import { Link, useNavigate } from "react-router-dom";
import { Button, InputField } from "../../components/forms";
import { emailReg } from "../../utils/regex";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MdErrorOutline } from "react-icons/md";

import { useMediaQuery } from "react-responsive";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { logo, reg_doodle } from "../../constants/imports";
import { onLogin } from "../../services/auth/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { captchakey } from "../../utils/URL";

const Login = () => {
  const { handleSubmit, control, watch } = useForm();
  const { setLoading, setConfig } = useStateSetter();
  const [error, seterror] = useState("");
  const [captcha, setcaptcha] = useState<string | null>(null);
  const navigate = useNavigate();

  const { isTabletOrMobile } = useStateGetter();
  const { setAuthuser } = useStateSetter();

  const watchedFields = watch(["email", "password"]);

  const areFieldsFilled = watchedFields.every(
    (field) => field !== "" && field !== undefined
  );

  const onSubmit = async (data: any) => {
    setLoading(true);
    let formData = {
      mail: data.email,
      password: data.password,
    };
    try {
      let res = await onLogin(formData);
      // if (res.user.checked != 0) {
      //   throw "User not verified, kindly verify your email address";
      // }
      localStorage.setItem(`UserData`, JSON.stringify(res?.user));
      localStorage.setItem(`AuthToken`, res?.token);
      setAuthuser(res?.user);
      const config = {
        headers: {
          Authorization: `Bearer ${res?.token}`,
        },
      };
      setConfig(config);
      navigate(`/dashboard`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      seterror(error);

      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className=" md:p-10 w-screen min-h-screen bg-customGray  flex flex-col md:grid md:grid-cols-2 items-center justify-center  ">
      {!isTabletOrMobile && (
        <div className="p-30 flex flex-col gap-10 justify-center items-center ">
          <img className="w-[203px]  " src={logo} />
          <img className="w-[330px]  " src={reg_doodle} />
        </div>
      )}

      <div
        className={`bg-white ${
          isTabletOrMobile ? " h-full" : ""
        }  md:max-h-[95vh] w-[100%] md:w-[458px] px-6 overflow-y-scroll  h-screen md:mx-20 md:py-10 md:px-20 shadow flex flex-col justify-center   `}
      >
        {isTabletOrMobile && (
          <div className="w-full flex flex-col justify-center items-center mb-2 ">
            <img className="w-[173px] self-center my-12  " src={logo} />
            <img className="w-[130px]  " src={reg_doodle} />
          </div>
        )}

        <p className="font-bold text-textbg  text-[27px] md:text-[24px] ">
          Welcome back! Please log in to continue.
        </p>
        <small className="font-medium text-textbg text-sm md:text-xs ">
          New here? {"  "}
          <Link className="text-primary" to="/signup">
            Create an account
          </Link>
        </small>
        <div className=" my-4">
          <InputField
            name="email"
            title="Email Address"
            placeholder="Enter Email Address"
            control={control}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />
          <InputField
            name="password"
            title="Password"
            placeholder="Enter Your Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />
          <div className="w-full flex flex-end m-2 justify-between mt-4">
            <p className="text-xs flex gap-1 font-light  text-textbg ">
              <input type="checkbox" />
              Remember me
            </p>
            <Link className="text-primary text-xs font-semibold" to="/reset">
              Forgot password
            </Link>
          </div>
          {error && (
            <p className="text-red-500 text-medium text-xs  mt-4 w-full text-center">
              <MdErrorOutline className="inline " size={20} />
              {error}
            </p>
          )}{" "}
          <ReCAPTCHA
            sitekey={captchakey}
            onChange={(val: any) => {
              setcaptcha(val);
            }}
          />
          <Button
            disabled={
             captcha == null ||
              !areFieldsFilled
            }
            text="Login"
            onBtnClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};

export { Login };
