import React, { useEffect } from "react";
import { useStateGetter } from "../hooks/statehooks/UseStateGettersHook";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom"
import {UserDataTable}  from "../components/generic/UsersTable";
import { ColumnDef } from "@tanstack/react-table";


export type Payment = {
    id: string
    amount: number
    status: "pending" | "processing" | "success" | "failed"
    email: string
  }
   
  export const columns: ColumnDef<Payment>[] = [
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "amount",
      header: "Amount",
    },
  ] 


function AdminprotectedRoutes() {
  const { isAdmin } = useStateGetter();
  const navigate = useNavigate();
  const isadmin = isAdmin();
  useEffect(() => {
    if (!isadmin) {
      toast("Unauthorised Access");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, []);
  return isadmin ? <div><UserDataTable  /></div> 
: <h1>Unathourized User</h1>
}

export default AdminprotectedRoutes;
