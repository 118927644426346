const ispremuser=(authuser:any) :boolean =>{


    if (authuser.exp_date == "NULL" || authuser.subscription_id=="NULL") {
        return(false);
      } 
      
      
      else {
        var today = new Date();
        var expirydate = new Date(authuser.exp_date);
        if (expirydate.getTime() <= today.getTime()) {
          return(false);
        } else {
          return(true);
        }
    
      }
    
  
}

export{ispremuser}