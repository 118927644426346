import { useEffect, useState } from "react";
import { useStateGetter } from "./statehooks/UseStateGettersHook";

export default () => {
  const { authuser } = useStateGetter();
  const [hasaccess, sethasaccess] = useState(false);

  useEffect(() => {

 if (authuser.exp_date == "NULL" || authuser.subscription_id=="NULL") {
      sethasaccess(false);
    } else {
      var today = new Date();
      var expirydate = new Date(authuser.exp_date);
      if (expirydate.getTime() <= today.getTime()) {
        sethasaccess(false);
      } else {
        sethasaccess(true);
      }
  
    }
  }, [authuser]);

  return { hasaccess, sethasaccess };
};
