import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StepWizard from "react-step-wizard";
import WizardStepIndicator from "wizard-step-indicator-react";
import Progressbar from "../../components/forms/Progressbar";
import Market from "./StepFlow/Market";
import TimeZone from "./StepFlow/TimeZone";
import Scanner from "./StepFlow/Scanner";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { GiRadarSweep } from "react-icons/gi";
import { PiClockFill } from "react-icons/pi";
import Result from "./StepFlow/SubFlows/Scanner/Result";
import { BsAppIndicator } from "react-icons/bs";
import { VscGithubAction } from "react-icons/vsc";
import Indicatorpick from "./StepFlow/StratGen/Indicatorpick";
import Timming from "./StepFlow/StratGen/Timming";
import StratScanner from "./StepFlow/StratScanner";
import PackageSelectionmodal from "../../components/generic/PackageSelectionmodal";
import usePremiumHook from "../../hooks/usePremiumHook";

export default () => {
  const [postdata, setpostdata] = useState({});
  const { element, setpercentage } = Progressbar();
  const [mainActive, setmainActive] = useState<number>(1);

  useEffect(() => {
    setpercentage((mainActive / 3) * 100);
  }, [mainActive]);

  const headerindicators = [
    { name: "Indicators", icon: <BsAppIndicator className="inline" /> },
    { name: "TradeTime", icon: <PiClockFill className="inline" /> },
    { name: "Strategy", icon: <VscGithubAction className="inline" /> },
  ];
  const navigate = useNavigate();
  const { hasaccess } = usePremiumHook();

  useEffect(() => {
    var root = document.getElementsByTagName("div");
    var rootArray = Array.from(root);
    rootArray.forEach((element) =>
      element.scrollTo({ top: 0, behavior: "smooth" })
    );
  }, [mainActive]);
  const IndicatorItem = ({
    icon,
    name,
    key_,
  }: {
    icon: JSX.Element;
    name: string;
    key_: number;
  }) => (
    <div
      className={`${
        mainActive == key_ + 1
          ? "!text-primary font-semibold"
          : " text-gray-400"
      } px-3 py-1 `}
    >
      {icon} <span className="ml-1">{name} </span>
    </div>
  );

  return (
    <div className="h-screen flex justify-center items-center pt-[12vh] md:pt-1  overflow-x-hidden overflow-y-hidden pb-[100px]  ">
      {!hasaccess && (
        <PackageSelectionmodal
          show={(state: boolean) => {
            navigate("/dashboard");
          }}
        />
      )}{" "}
      <div className="bg-white rounded  shadow flex flex-col  h-screen md:max-h-[90vh]   px-4 py-8 overflow-x-hidden  items-center md:w-[80%] min-h-[80%]">
        <h1 className="text-lg flex justify-center items-center font-semibold self-center ">
          Step {mainActive} of 3
        </h1>

        <div className="md:w-[60%]">
          <p className="text-sm text-gray-500 my-1">Trade at the right time</p>
          {element}
        </div>
        <div className="w-full flex justify-center pl-6">
          {headerindicators.map((item: any, index) => (
            <IndicatorItem icon={item.icon} key_={index} name={item.name} />
          ))}
        </div>
        <StepWizard
        className="w- full flex "
          onStepChange={(stepChange: {
            previousStep: number;
            activeStep: number;
          }) => {
            setmainActive(stepChange.activeStep);
            var root = document.getElementsByTagName("div");
            var rootArray = Array.from(root);
            rootArray.forEach((element) =>
              element.scrollTo({ top: 0, behavior: "smooth" })
            );
          }}
        >
          <Indicatorpick setpostdata={setpostdata} />
          <Timming postdata={postdata} setpostdata={setpostdata} />
          <StratScanner postdata={postdata} setpostdata={setpostdata} />
        </StepWizard>
      </div>
    </div>
  );
};
